<script setup lang="ts">
const scrollPosition = ref(0)
const showCasePosition = computed(() => Math.min(80, scrollPosition.value * 0.2) - 80)

function onScroll() {
  scrollPosition.value = window.scrollY
}

onMounted(() => {
  window.addEventListener('scroll', onScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', onScroll)
})
</script>

<template>
  <div class="mail-showcase">
    <NuxtImg :style="{top: showCasePosition +'px'}" src="/img/app-screenshot.webp" alt="Silvabird webmail screenshot" width="1200px" />
  </div>
  <div class="mail-showcase-slot">
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
.mail-showcase {
  display: flex;
  margin: 0 min(150px, 10vw) 80px min(150px, 10vw);
}

.mail-showcase-slot{
  margin: 0 min(150px, 10vw) 80px min(150px, 10vw);
}

img {
  position: relative;
  z-index: -1;
  width: 100%;
  border-radius: 14px;
  vertical-align: bottom;
  border: 8px solid var(--background);
  box-shadow: 0px -10px 200px 10px #2f265c;
}
</style>
